
















































































































































































import Vue from 'vue';
import Place from '@/dtos/place';
import RideeEvent from './event';
import { router } from '@/router';
import LocationField from '@/location/LocationField.vue';
import Hint, { RideeHints } from '@/components/Hint.vue';
import { tomorrowIfSundayOrNextWeekend } from '@/utils/date_time';
import { isMobileViewport } from '@/utils/platform';
import { defaultEventImageURL } from '@/defaults/defaults';
import EditorMode from '@/common/editor.mode';
import EditIcon from '@/assets/mobile/icons/edit-icon.svg';
import SharedFields from '@/add/shared.fields';
import BaseFields from '@/add/base.fields';
import RideeEventFields from '@/event/event.fields';

class EventFields extends BaseFields implements RideeEventFields {
  endDateTime?: Date = undefined;
  externalURL?: string = undefined;
  tags: string[] = [];

  populate(event: RideeEvent) {
    this.title = event.title;
    this.description = event.description;
    this.startDateTime = event.startDateTime;
    this.endDateTime = event.endDateTime;
    this.location = event.location;
    this.externalURL = event.externalURL;
    this.tags = event.tags;
  }

  override startDateTimeDidChange(newDate: Date, oldDate: Date) {
    const diff = this.endDateTime
      ? this.endDateTime.getTime() - oldDate.getTime()
      : -1;
    if (diff > 0) this.endDateTime = new Date(newDate.getTime() + diff);
  }

  mandatoryFilled(): boolean {
    const now = new Date();
    const fields = [this.title, this.location.description];
    function notEmpty(str: string): boolean {
      return str.trim().length > 0;
    }
    return (
      fields.every(notEmpty) &&
      (!this.endDateTime || this.endDateTime >= this.startDateTime) &&
      (this.startDateTime > now ||
        (this.endDateTime != undefined && this.endDateTime > now))
    );
  }
}

const draft = (shared?: SharedFields): RideeEvent =>
  new RideeEvent({
    id: '',
    organizerId: '',
    organizerUsername: '',
    title: shared?.title || '',
    description: shared?.description || '',
    startDateTime: shared?.startDateTime || tomorrowIfSundayOrNextWeekend(),
    location: shared?.location || Place.void(),
    interestCount: 0,
    tags: [],
  });

export default Vue.extend({
  name: 'EventForm',
  components: { Hint, LocationField, EditIcon },
  props: {
    eventId: {
      type: String,
    },
    sharedFields: {
      type: SharedFields,
      required: false,
      default: undefined,
    },
    mode: {
      type: EditorMode,
      default() {
        return EditorMode.default;
      },
    },
  },
  data() {
    return {
      fields: new EventFields(this.sharedFields),
      headerImage: undefined as File | undefined,
      headerImagePreviewURL: undefined as string | undefined,
      defaultHeaderImageURL: undefined as string | undefined,
      hintID: undefined as string | undefined,
    };
  },
  computed: {
    isSubmitable(): boolean {
      return this.fields.mandatoryFilled();
    },
    buttonTitle(): string {
      return this.mode.isEdit ? 'Event aktualisieren' : 'Event erstellen';
    },
  },
  async mounted() {
    if (this.mode.isCreate) {
      this.defaultHeaderImageURL = await defaultEventImageURL();
      this.populate(draft(this.sharedFields));
    } else {
      await this.$store.dispatch('event/bindEvent', this.eventId);
      const event = this.$store.getters['event/current'];
      this.populate(event);
    }
    this.hintID = RideeHints.descriptionLinks;
  },
  methods: {
    onLocationChange(p: Place) {
      this.fields.location = p;
    },
    onHeaderImageSelect(event: any) {
      const file = event.target.files[0];
      this.headerImage = file;
      this.headerImagePreviewURL = URL.createObjectURL(file);
    },
    populate(event: RideeEvent) {
      this.fields.populate(event);
      this.headerImagePreviewURL =
        event.headerImageURL || this.defaultHeaderImageURL;
    },
    submit() {
      const dispatch = this.$store.dispatch;
      if (this.mode.isEdit) {
        dispatch('event/update', {
          fields: this.fields,
          image: this.headerImage,
        }).then(this.routeToDetail);
      } else {
        dispatch('event/create', {
          fields: this.fields,
          image: this.headerImage,
          defaultImageURL: this.defaultHeaderImageURL,
        }).then(this.routeToDetail);
      }
    },
    routeToDetail(eventID: string) {
      const routeName = isMobileViewport()
        ? 'Mobile Event Detail'
        : 'Event Detail';
      router.replace({
        name: routeName,
        params: { eventId: eventID },
      });
    },
  },
});
