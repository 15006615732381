













import Vue from 'vue';
import Scaffold from '@/components/Scaffold.vue';
import TourForm from '@/tour/TourForm.vue';
import EventForm from '@/event/EventForm.vue';
import SegmentedControl from '@/control/SegmentedControl.vue';
import EntityType from '@/common/entity.type';
import SharedFields from '@/add/shared.fields';
// import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { Scaffold, TourForm, EventForm, SegmentedControl },
  props: {
    type: {
      type: EntityType,
      required: false,
      default() {
        return EntityType.tour;
      },
    },
  },
  data() {
    return {
      sharedFields: new SharedFields(),
      segment: EntityType.tour.id,
      segments: [
        {
          title: 'Tour',
          id: EntityType.tour.id,
        },
        {
          title: 'Event',
          id: EntityType.event.id,
        },
      ],
    };
  },
  computed: {
    isTourForm(): boolean {
      return EntityType.byId(this.segment).isTour;
    },
    isEventForm(): boolean {
      return EntityType.byId(this.segment).isEvent;
    },
  },
});
